import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";

const Footer = ({ data, varient }) => {
    const { subTitle, bgImg } = data;
    const currentYear = new Date().getFullYear();

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const [scrollPosition, setScrollPosition] = useState(0);

    const handleScroll = () => {
        const currentPosition = window.scrollY;
        setScrollPosition(currentPosition);
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    return (
        <footer
            className={`st-site-footer st-sticky-footer st-dynamic-bg ${
                varient ? varient : ""
            }`}
            style={{ backgroundImage: `url(${bgImg})` }}
        >
            <div className="st-main-footer">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="st-footer-widget">
                                <div className="st-text-field">
                                    {/* <img src={logo} alt={logo} className="st-footer-logo" /> */}
                                    <div className="st-height-b25 st-height-lg-b25" />
                                    <div className="st-footer-text">
                                        {subTitle}
                                    </div>
                                    <div className="st-height-b25 st-height-lg-b25" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="st-footer-widget"></div>
                        </div>
                        <div className="col-lg-3">
                            <div className="st-footer-widget">
                                <h2 className="st-footer-widget-title">
                                    Servicios
                                </h2>
                                <ul className="st-footer-widget-nav st-mp0">
                                    <li>
                                        <Link to="">
                                            <Icon icon="fa:angle-right" />
                                            Diagnóstico on-line
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="">
                                            <Icon icon="fa:angle-right" />
                                            Video consulta
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="">
                                            <Icon icon="fa:angle-right" />
                                            Evaluación IA
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="st-footer-widget">
                                <h2 className="st-footer-widget-title">
                                    Contacto
                                </h2>
                                <ul className="st-footer-contact-list st-mp0">
                                    <li>
                                        <span className="st-footer-contact-title">
                                            Dirección:
                                        </span>{" "}
                                        Iquique, Chile
                                    </li>
                                    <li>
                                        <span className="st-footer-contact-title">
                                            Correo:
                                        </span>{" "}
                                        hello@odontify.app
                                    </li>
                                    <li>
                                        <span className="st-footer-contact-title">
                                            Telefono:
                                        </span>{" "}
                                        (+56) 93645742
                                    </li>
                                </ul>
                            </div>
                        </div>
                        {/* .col */}
                    </div>
                </div>
            </div>
            <div className="st-copyright-wrap">
                <div className="container">
                    <div className="st-copyright-in">
                        <div className="st-left-copyright">
                            <div className="st-copyright-text">
                                Copyright {currentYear}. Design by Odontify
                            </div>
                        </div>
                        <div className="st-right-copyright">
                            <div
                                id="st-backtotop"
                                style={{
                                    scale: `${
                                        scrollPosition >= 100 ? "1" : "0"
                                    }`,
                                }}
                                onClick={scrollToTop}
                            >
                                <Icon icon="fa6-solid:angle-up" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
