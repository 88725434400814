import React from "react";
import Hero from "../components/Hero/";
import Iconbox from "../components/Iconbox";
import Team from "../components/Slider/TestimonialSlider";
import Steps from "../components/Steps";

const Home = () => {
    return (
        <>
            <Hero />
            <Iconbox />
            <Steps />
            <Team />
        </>
    );
};

export default Home;
