import React, { memo } from "react";
import ReactDOM from "react-dom";

const modal = document.getElementById("modal-overlay");

const Modal = ({ isOpen, onClose, title, children }) => {
    if (!isOpen) return null;

    return ReactDOM.createPortal(
        <div
            className={`modal-overlay ${isOpen ? "open" : ""}`}
            onClick={onClose}
        >
            <div
                className="modal-container"
                onClick={(e) => e.stopPropagation()}
            >
                <button className="modal-close" onClick={onClose}>
                    ×
                </button>
                <div className="modal-title">{!!title && <h3>{title}</h3>}</div>
                <div className="modal-content">{children}</div>
            </div>
        </div>,
        modal
    );
};

export default memo(Modal);
