import { React, useState } from "react";
import Modal from ".";

const DiagnosisModal = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    return (
        <>
            <button
                onClick={openModal}
                className="st-btn st-style1 st-color1 st-smooth-move"
            >
                Diagnóstico
            </button>
            <Modal isOpen={isModalOpen} onClose={closeModal} />
        </>
    );
};

export default DiagnosisModal;
