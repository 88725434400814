import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../Header';
import Footer from '../Footer';

const headerData = {
	logo: '/images/logo.png',
};

const footerData = {
	logo: '/images/footer-logo.png',
	bgImg: '/images/footer-bg.png',
	subTitle:
		'Odontify es un proyecto pensado para hacer más accesible y transparente la evaluación dental.',
};

const Layout = () => (
	<>
		<Header data={headerData} />
		<Outlet />
		<Footer data={footerData} />
	</>
);

export default Layout;
