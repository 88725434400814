import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import Layout from './components/Layout';
import Home from './pages/Home';

function App() {
	const { pathname } = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	return (
		<Routes>
			<Route path="/" element={<Layout />}>
				<Route index element={<Home />} />
			</Route>
		</Routes>
	);
}

export default App;
